<template>
  <div>
    <base-loader :isLoading="isLoading"></base-loader>
    <base-dialog-notification ref="editUserProfileConfirm" />
    <base-snackbar-notification ref="editUserProfileSnackbar" />
    <v-card elevation="0">
      <v-card-text>
        <v-form ref="userForm" @submit.prevent="save()">
          <v-row class="align-center mb-5 ml-1 mt-1">
            <v-avatar v-if="userModel.profilePicture" size="50">
              <v-img :src="userModel.profilePicture"></v-img>
            </v-avatar>
            <v-avatar v-else size="50">
              <v-img src="../../assets/avatar.png"></v-img>
            </v-avatar>
            <span class="ml-4 text-h3 black--text">{{ userModel.firstName }} {{ userModel.lastName }}</span>
          </v-row>
          <v-expansion-panels class="mb-8">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <h3>Your Personal Details</h3>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row  class="pt-4">
                  <v-col md="6" class="py-0">
                    <v-text-field
                      label="First Name"
                      v-model="userModel.firstName"
                      outlined
                      readonly
                      dense
                      class="text-body-1"
                    ></v-text-field>
                  </v-col>
                  <v-col md="6" class="py-0">
                    <v-text-field
                      label="Last Name"
                      v-model="userModel.lastName"
                      outlined
                      hint
                      readonly
                      dense
                      class="text-body-1"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md="12" class="py-0">
                    <mobile-number-input
                        @updateMobileNumberInput="mobileNumberFunc"
                        :allowTestNumbers="true"
                        :initialValue="userModel.telNumber"
                        class="text-body-1 py-0"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md="12" class="py-0">
                    <v-text-field
                      label="Email"
                      v-model="userModel.email"
                      outlined
                      dense
                      class="text-body-1"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12" v-if="showIdNumberPassportSelect" class="py-0">
                    <base-identification-type-picker
                        :initial="'South African ID'"
                        label="Identification Type"
                        outlined
                        :chosen-identification-types.sync="idType"
                        dense
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12" v-if="idType == 'South African ID'" class="py-0">
                    <v-text-field
                        label="ID Number"
                        v-model="userModel.idNumber"
                        outlined
                        class="text-body-1"
                        dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12" class="py-0" v-else>
                    <v-text-field
                        label="Passport Number"
                        v-model="userModel.passport"
                        outlined
                        class="text-body-1"
                        dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12" sm="12" class="py-0">
                    <v-textarea
                      label="Address"
                      outlined
                      counter
                      v-model="userModel.fullAddress"
                      dense
                      class="text-body-1"
                    />
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <h3>Payment Details</h3>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="12" class="d-flex mx-auto">
                    <v-alert
                        dense
                        outlined
                        type="info"
                    ><span class="font-weight-black">Note: </span>Only your employer can change this information.</v-alert>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12" class="py-0">
                  <v-text-field
                    label="Account Holder Name"
                    v-model="userModel.accountHolder"
                    outlined
                    dense
                    class="text-body-1"
                  ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12" class="py-0">
                  <v-text-field
                    label="Account Number"
                    v-model="userModel.accountNumber"
                    outlined
                    dense
                    class="text-body-1"
                  ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12" class="py-0">
                    <base-bank-picker
                        :chosen-bank.sync="userModel.bankName"
                        :initial="userModel.bankName"
                        label="Bank Name"
                        outlined
                        class="text-body-1"
                        dense
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12" class="py-0">
                    <base-bank-branch-picker
                        :chosen-bank-branch.sync="userModel.branchCode"
                        :initial="userModel.branchCode"
                        label="Branch Code"
                        outlined
                        class="text-body-1"
                        dense
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12" class="py-0">
                    <base-account-type-picker
                        :chosenAccountType.sync="userModel.accountType"
                        :initial="userModel.accountType"
                        label="Account Type"
                        required
                        outlined
                        hint="Capitec, Tyme and Discovery bank are Savings"
                        :rules="[(v) => !!v || 'Account type is required']"
                        dense
                        class="text-body-1"
                    />
                  </v-col>
                </v-row>
              </v-expansion-panel-content></v-expansion-panel
            >
            <v-expansion-panel>
              <v-expansion-panel-header>
                <h3>Contract Details</h3>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="12" class="d-flex mx-auto">
                    <v-alert
                        dense
                        outlined
                        type="info"
                    ><span class="font-weight-black">Note: </span>Only your employer can change this information.</v-alert>
                  </v-col>
                </v-row>
                <v-row class="pt-0">
                  <base-view-contract-form
                      ref="employeeViewContractForm"
                      v-bind:edit="false"
                      v-bind:showActions="false"
                      :userId="this.userModel.id"
                      class="pt-0"
                  />
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-row>
            <v-col>
              <v-btn type="submit" color="primary" class="" elevation="0">
                Save
              </v-btn>
              <v-btn color="warning" outlined class="ml-4" @click="resetPassword()">
                Reset Password
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { User } from "@/models/User";
import { gql } from "apollo-boost";
import defaultProfile from "../../assets/avatar.png";
import UserProfilePictureUpdate from "@/components/Base/UserProfilePictureUpdate.vue";
import MobileNumberInput from '@/components/Base/MobileNumberInput.vue'

export default {
  components: { UserProfilePictureUpdate, MobileNumberInput },
  name: "My Profile",
  mounted() {
    this.$emit("update:pageName", this.$options.name);
  },
  data() {
    return {
      isLoading: false,
      paymentSetupType: "Employer full control",
      userModel: {
        id: "",
        role: "",
        firstName: "",
        lastName: "",
        telNumber: "",
        email: "",
        idNumber: "",
        passport: "",
        accountNumber: "",
        accountType: "",
        bankName: "",
        fullAddress: "",
        profilePicture: "",
        branchCode: "",
        checkIn: {},
        profileStatus: false,
        paymentSetupType: "",
        workAddress: "",
      },
      idType: "South African ID",
      menu: false,
      date: null,
      search: "",
      dialog3: false,
      totalRecords: 0,
      // id: "",
    };
  },
  created() {
  },
  computed: {
    showIdNumberPassportSelect() {
      if (this.$_.isEmpty(this.userModel.idNumber)) {
        this.idType = "Passport"
      } else {
        this.idType = "South African ID"
      }
      return (this.$_.isEmpty(this.userModel.idNumber) && this.$_.isEmpty(this.userModel.passport))
    },
  },
  apollo: {
    me: {
      query: gql`
        query {
          me {
            id
            firstName
            lastName
            email
            telNumber
            fullAddress
            profilePicture
          }
        }
      `,
      fetchPolicy: "network-only",
      result(response) {
        if(this.$_.has(response.data.me, 'id')) {
          this.id = response.data.me.id;
          this.getUser(response.data.me.id)
          .then((response) => {
            if(this.$_.has(response.data.user, 'id')) {
              const user = response.data.user
              this.userModel.id = user.id;
              this.userModel.role = user.role;
              this.userModel.firstName = user.firstName;
              this.userModel.lastName = user.lastName;
              this.userModel.telNumber = user.telNumber;
              this.userModel.email = user.email;
              this.userModel.idNumber = user.idNumber;
              this.userModel.fullAddress = user.fullAddress;
              this.userModel.passport = user.passport;
              this.userModel.bankName = user.bankName;
              this.userModel.branchCode = user.branchCode;
              this.userModel.accountType = user.accountType;
              this.userModel.accountNumber = user.accountNumber;
              this.userModel.accountHolder = user.accountHolder;
              this.userModel.workAddress = user.workAddress;
            }
          }).catch((error) => {
            console.log('error', error)
            this.$refs.editUserProfileConfirm.open('Error', 'There was a problem while retrieving your personal details. Please try again or contact support.', 'error')
          })
        }
      },
    },
  },
  methods: {
    mobileNumberFunc(value) {
      this.userModel.telNumber = value
    },
    async getUser(id) {
      return new Promise(async (resolve, reject) => {
        const self = this;
        await self.$apollo.query({
          query: gql`
            query User($id: ID!) {
              user(id: $id) {
                id
                firstName
                lastName
                email
                telNumber
                fullAddress
                profilePicture
                idNumber
                passport
                bankName
                branchCode
                accountType
                accountNumber
                accountHolder
                role
                workAddress
              }
          }
        `,
          fetchPolicy: "no-cache",
          variables: {
            id: id,
          },
        }).then((response) => {
          resolve(response)
        }).catch((error) => {
          self.errorMessage = _.get(error, 'graphQLErrors[0].message', `Sorry, there was a system error. Please try again or contact support. ${error}`);
          reject(self.errorMessage)
        })
      })
    },
    async resetPassword() {
      const self = this;
      const dialogAnswer = await this.$refs.editUserProfileConfirm.open("Reset Password", "<span class=\"font-weight-black\">Are you sure you want to reset your password?</span><p>You will receive an SMS with instructions on what to do.</p>", "warning", "yesno")
      if (!dialogAnswer) return
      self.isLoading = true;
      self.successMessage = "";
      self.errorMessage = "";
      this.$apollo.mutate({
        mutation: gql`
          mutation ($telNumber: String!) {
            getResetPasswordLink(telNumber: $telNumber)
          }
        `,
        variables: {
          telNumber: this.userModel.telNumber,
        },
      }).then((response) => {
        this.$refs.editUserProfileConfirm.open('Success', `Password Reset Link sent to your mobile phone: <span class="font-weight-black">${this.userModel.telNumber}</span></span>`, 'success')
        self.isLoading = false;
      }).catch((error) => {
        self.errorMessage = _.get(error, 'graphQLErrors[0].message', `Sorry, there was a system error. Please try again or contact support. ${error}`);
        this.$refs.editUserProfileConfirm.open('Error', self.errorMessage, 'error')
        self.isLoading = false;
      })
    },
    defaultProfileImage() {
      return defaultProfile;
    },
    async save() {
      this.isLoading = true
      const self = this
      if (!this.$refs.userForm.validate()) {
        self.isLoading = false
        return
      }

      const updateUserDetails = {
        id: this.userModel.id,
        firstName: this.userModel.firstName,
        lastName: this.userModel.lastName,
        telNumber: this.userModel.telNumber,
        email: this.userModel.email,
        idNumber: this.userModel.idNumber,
        fullAddress: this.userModel.fullAddress,
        passport: this.userModel.passport
      }

      await this.updateUser(updateUserDetails)
        .then(() => {
          this.isLoading = false
          this.$refs.editUserProfileSnackbar.open('The details have been saved.', 'success')
        })
        .catch((e) => {
          this.isLoading = false
          this.$refs.editUserProfileConfirm.open("Error", "There was an error saving your details. Please try again or contact support.", "error")
        })
    },
    async updateUser(user) {
      const self = this;
      await self.$apollo.mutate({
        mutation: gql`
          mutation updateUser($user: UserUpdateInput!) {
            updateUser(user: $user) {
              id
              updated
            }
          }
        `,
        variables: {
          user: user
        },
      }).then((res) => {
        console.log("res", res)
        return res.data.updateUser
      })
      .catch((e) => {
        throw new Error(e)
      })
    },
  },
};
</script>

<style lang="scss" scoped></style>
